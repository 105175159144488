html {
  /* background-color: #2a4365; */
}
body {
  /* background-color: #2a4365 !important; */
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 7px !important;
  /* height: 7px !important; */
}

::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
  /* width: 5px !important; */
  /* border-radius: 0px !important; */
  /* border-radius: 5px !important; */
  /* background-clip: padding-box; */
}

::-webkit-scrollbar-thumb {
  /* background: linear-gradient(180deg, #6a11cb 0%, #2575fc 100%); */
  /* background: #bbb; */
  /* background: transparent; */
  /* background: rgba(3,7,18,1); */
  background: rgb(55 65 81);
  /* background: rgba(28,37,52,1); */
  border-radius: 5px !important;
  /* width: 10px !important; */

  /* background: #fff; */
  /* border: 1px solid rgb(55 65 81); */
  /* background-clip: padding-box; */

}

::-webkit-scrollbar-thumb:hover {
  /* background: linear-gradient(180deg, #6a11cb 20%, #2575fc 80%); */
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes cardZoom {
  0% {
    opacity: 0;
    transform: scale(0.98) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Header */
header nav.socials a {}
header nav.socials a:hover {
  transform: scale(1.1);
}

header nav.menu a {}
header nav.menu a:hover {
  color: rgb(209 213 219);
}

/* Filter bar */
.filterBar {
}

.card a {
  /* text-decoration: underline !important; */
}
.card img {
  /* transform: scale(1); */
  /* transition: transform 0.3s ease; */
}
.card:hover img {
  /* transform: scale(1.1); */
  /* transition: transform 5s ease-out; */
}
.card:hover {
  /* transform: translate3d(0, 0px, 0);
  transition:
    box-shadow 0.4s ease-out,
    transform 0.4s ease-out; */
}
.card {
  transform: translate3d(0, 0, 0);
  transition:
    box-shadow 0.4s ease-out,
    transform 0.4s ease-out;
  animation-name: cardZoom;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
  animation-delay: var(--delay);
}

.card .links .icon {}
.card .links .chakra-button .icon {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.card .links .chakra-button:hover .icon {
  transform: translateX(3px);
}

.card .tech-stack {

}

.no-projects {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-name: zoomIn;
}

.pagination {
}
.pagination .chakra-button {
  min-width: 40px;
}
.pagination .chakra-button.active {
  background-color: rgb(28,37,52);
  border: 1px solid #fff;
  color: #fff;
  cursor: default;
  pointer-events: none;
}

.chakra-react-select__input-container {
  flex: 0 0 auto !important;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.video-container {
  position: relative;
  width: 100%;
}
.video-container img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.video-container .iframe-container {
  width: 100%;
  padding-bottom: 56%;
}
.video-container .iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.video-container .video-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  z-index: 99;
  cursor: pointer;
  transition: all 0.7s ease;
  /* transform: translateY(100%); */
}

/* .video-container[data-state="playing"] .video-play { */
.video-container[data-state] .video-play {
  opacity: 0;
  visibility: hidden;
}
.video-container .video-play img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video-container .video-play .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container .video-play .icon i {
  font-size: 60px;
  color: #fff;
}
.video-container .video-play .icon .fa-youtube {
  position: relative;
  color: #f00;
  font-size: 60px;
}
.video-container .video-play .icon .fa-youtube:before {
  position: relative;
  z-index: 1;
}
.video-container .video-play .icon .fa-youtube:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 50%;
  height: 50%;
  left: 0;
  top: 0;
  transform: translate(50%, 50%);
  z-index: 0;
}
