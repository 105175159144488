.Loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  max-width: 100%;
  /* margin-top: 7.3rem; */
  /* margin-bottom: 7.3rem; */
}
.Loader:before, .Loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 200ms;
  filter: drop-shadow(0 0 0.7555555556rem rgba(255, 255, 255, 0.75));
}
.Loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 0 #fff;
  animation-name: pulsC;
}
/* .Loader:after {
  width: calc(100% - 0.8rem*2);
  padding-bottom: calc(100% - 0.8rem*2);
  box-shadow: 0 0 0 0 #fff;
  animation-name: pulsB;
} */

@keyframes pulsC {
  0% {
	 --animation-progress: 0;
  }
  10% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0.5;
	 --animation-progress: 10;
  }
  50% {
    box-shadow: inset 0 0 0 0.9rem #fff;
    opacity: 1;
	 --animation-progress: 50;
  }
  80% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0.5;
	 --animation-progress: 80;
  }
  100% {
	 --animation-progress: 100;
  }
}

@keyframes pulsA {
  0% {
    box-shadow: inset 0 0 0 0.8rem #fff;
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #fff;
    opacity: 0;
  }
}
@keyframes pulsB {
  0%, 50% {
    box-shadow: 0 0 0 0 #fff;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.8rem #fff;
    opacity: 1;
  }
}

.animateContent {
	animation-duration: 1.7s;
	animation-timing-function: ease-out;
	animation-name: reveal;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}